<template>
	<main>
		<component :class="section.classList" v-for="(section, index) in layout" :key="index" :is="section.layout" :data="section"></component>
	</main>
</template>

<script>
	// eslint-disable-next-line no-unused-vars
	import layout from '@/assets/data/home.json';

	export default {
		name: 'Home',

		data() {
			return {
				layout: layout
			}
		},

		created() {
			if (Object.values(layout).length > 0) {
				Object.values(layout).map(comp => {
					if (typeof comp.id === 'undefined') {
						return;
					}

					if (typeof comp.classList === 'undefined') {
						comp.classList = 'has_identifier';
					} else {
						comp.classList += ' has_identifier';
					}
				});
			}
		}
	}
</script>